import React, { useMemo, useState } from "react";
import MapContainer from "./components/Map";
import Widget from "./components/Widget/Widget";
import { useGTM } from "./hooks/use-gtm";
import { useJsApiLoader } from "@react-google-maps/api";
import { initializeDataDog } from "./libs/datadog.ts";

initializeDataDog();
const App = (props) => {
  useGTM();
  const apiKey = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    if(params.get("key") === process.env.REACT_GOOGLE_MAPS_API_KEY_DEPRECATED || params.get("key") === process.env.REACT_GOOGLE_MAPS_API_KEY_DEPRECATED_2){
       return process.env.REACT_GOOGLE_MAPS_API_KEY
    }
    else {
      return params.get("key") || process.env.REACT_GOOGLE_MAPS_API_KEY;
    }
  }, []);

  if (!navigator.geolocation) {
    alert("tu navegador no tiene opción de geolocaclización");
    throw new Error("tu navegador no tiene opción de geolocaclización");
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
    libraries: ["places"],
  });

  return (
    <>
      {isLoaded && (
        <Widget
          onChangeSelection={props.outputFunction}
          configs={props.configs}
          address={props.address}
        />
      )}
      <style>{`pu-do, pu-do > div { height: 100%;}`}</style>
    </>
  );
};

export default App;
